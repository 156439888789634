import React, { useState } from "react"
import { Flex } from "@chakra-ui/react"
import PropTypes from "prop-types"

import Layout from "../components/Layout"
import Section from "../components/Section"
import SliceZone from "../components/SliceZone"
import ArticleGroup from "./hub/article-group"
import FilterLink from "./hub/filter-link"

import { getImage } from "gatsby-plugin-image"

const ALL = "All"

const FilterGroup = ({ activeGroup, groups, setActiveGroup }) => (
  <Flex
    sx={{
      borderBottom: (theme) => `2px solid ${theme.colors.secondary}`,
      pb: 2,
      flexWrap: "wrap",
    }}
  >
    <FilterLink
      key={`filter-${ALL}`}
      activeGroupId={activeGroup}
      groupId={ALL}
      setActiveGroup={setActiveGroup}
      text={ALL}
    />
    {groups
      .filter((g) => Array.isArray(g.guides) && g.guides.length > 0)
      .map((g) => (
        <FilterLink
          key={`filter-${g.label}`}
          activeGroupId={activeGroup}
          groupId={g.label}
          setActiveGroup={setActiveGroup}
          text={g.label}
        />
      ))}
  </Flex>
)

FilterGroup.propTypes = {
  activeGroup: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  setActiveGroup: PropTypes.func.isRequired,
}

const Hub = ({ hub, groups }) => {
  const [activeGroup, setActiveGroup] = useState(ALL)
  const isFiltered = (active, group) => ![ALL, group].includes(active)
  const displayGroupTitle = (active, group) => groups.length !== 1 && active !== group

  return (
    <Layout heroCopy={hub.title} heroImage={hub.heroimage ? getImage(hub.heroimage) : null}>
      {hub.body && hub.body.length !== 0 && (
        <Section>
          <SliceZone allSlices={hub.body} />
        </Section>
      )}
      {groups.length > 1 ? (
        <Section>
          <FilterGroup activeGroup={activeGroup} groups={groups} setActiveGroup={setActiveGroup} />
        </Section>
      ) : null}
      {groups.map((g) => (
        <ArticleGroup
          key={`group-${g.label}`}
          displayTitle={displayGroupTitle(activeGroup, g.label)}
          title={g.label}
          guides={g.guides}
          filtered={isFiltered(activeGroup, g.label)}
        />
      ))}
    </Layout>
  )
}

Hub.propTypes = {
  hub: PropTypes.shape({
    body: PropTypes.array,
    title: PropTypes.string.isRequired,
    heroimage: PropTypes.object,
  }).isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Hub
