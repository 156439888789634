import React from "react"
import { Heading, SimpleGrid } from "@chakra-ui/react"
import PropTypes from "prop-types"

import Section from "../../components/Section"
import ArticleCard from "./article-card"

const ArticleGroup = ({ displayTitle, filtered, guides, title }) => {
  if (filtered) {
    return null
  }
  const articles = guides.map((g) => <ArticleCard key={g.uid} article={g} />)
  return (
    <Section>
      <SimpleGrid my={16} columns={[1, 3]} spacing={12}>
        {displayTitle && (
          <Heading as="h2" variant="h2" mt={8} sx={{ gridColumn: "1 / -1" }}>
            {title}
          </Heading>
        )}
        {articles}
      </SimpleGrid>
    </Section>
  )
}

ArticleGroup.propTypes = {
  displayTitle: PropTypes.bool.isRequired,
  guides: PropTypes.arrayOf(PropTypes.object).isRequired,
  filtered: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

export default ArticleGroup
