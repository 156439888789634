import React from "react"
import { chakra, Box, Text } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "../../components/Link"
import { linkResolver } from "../../gatsby/linkResolver"

const articleTypeToType = (articleType) => {
  const dict = {
    "Destination Guide": "Destination Guide",
    "Destination Sailing Guide": "Sailing Guide",
    "Destination Top Guide": "Top Tips",
    "Sailing Tips Guide": "Sailing Tips",
    "Inspiration Sailing Guide": "Sailing Inspirations",
    "Inspiration Top Guide": "Top Tips",
    "Default Guide": "Guide",
    "Legal & Informational Document": "Guide",
  }
  return dict[articleType] || "Guide"
}

const SImg = chakra(GatsbyImage)
const ArticleCard = ({ article }) => {
  return (
    <Box>
      <Box as={Link} to={article.url || linkResolver(article)}>
        <SImg
          image={getImage(article.data.heroimage)}
          sx={{
            height: "236px",
            "&:hover": {
              opacity: ".85",
              transition: "opacity .2s ease-out",
            },
          }}
        />
        <chakra.span sx={{ display: "block", textTransform: "uppercase" }}>
          {articleTypeToType(article.data.articletype)}
        </chakra.span>
        <chakra.h3
          sx={{
            my: 4,
            fontSize: "lg",
            color: "text",
            fontWeight: "bold",
            "&:hover": {},
          }}
        >
          {article.data.title}
        </chakra.h3>
        <Text noOfLines={2} color="text" my={4}>
          {article.data.previewtext}
        </Text>
      </Box>
      {article.data.author && article.data.author.document && (
        <Link to={linkResolver(article.data.author.document)}>{article.data.author.document.data.name}</Link>
      )}
      <chakra.span sx={{ color: "muted", fontSize: "sm" }}>{` - ${article.first_publication_date}`}</chakra.span>
    </Box>
  )
}

ArticleCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  article: PropTypes.object.isRequired,
}

export default ArticleCard
