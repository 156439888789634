import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Hub from "./hub"

const filterData = (data, articletype) => data.allPrismicArticle.nodes.filter((n) => n.data.articletype === articletype)

const DestinationHub = ({ data }) => {
  const hub = filterData(data, "Destination Hub")[0].data
  const guides = filterData(data, "Destination Guide")
  const sailingGuides = filterData(data, "Destination Sailing Guide")
  const topGuides = filterData(data, "Destination Top Guide")
  const groups = [
    { label: "Destinations", guides },
    { label: "Sailing Guides", guides: sailingGuides },
    { label: "Top Things to Do", guides: topGuides },
  ].filter((g) => g.guides.length)

  return <Hub hub={hub} groups={groups} />
}

export const query = graphql`
  query DestinationHubQuery($country: String!) {
    allPrismicArticle(
      filter: {
        data: {
          country: { eq: $country }
          articletype: {
            in: ["Destination Hub", "Destination Guide", "Destination Sailing Guide", "Destination Top Guide"]
          }
        }
      }
      sort: { fields: [first_publication_date], order: DESC }
    ) {
      nodes {
        data {
          country
          location
          title
          previewtext
          articletype
          author {
            document {
              ... on PrismicAuthor {
                data {
                  name
                  avatar {
                    fluid {
                      __typename
                    }
                  }
                }
                first_publication_date
                uid
                type
              }
            }
          }
          heroimage {
            gatsbyImageData(height: 700)
          }
          body {
            ... on PrismicArticleDataBodyRichText {
              id
              slice_type
              primary {
                text {
                  richText
                }
              }
            }
            ... on PrismicArticleDataBodyImage {
              id
              slice_type
              primary {
                caption
                image {
                  gatsbyImageData(width: 1280, height: 500)
                }
              }
            }
          }
        }
        first_publication_date(formatString: "DD MMMM YYYY")
        uid
        type
      }
    }
  }
`

DestinationHub.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

export default DestinationHub
