import React from "react"
import { Button } from "@chakra-ui/react"
import PropTypes from "prop-types"

const FilterLink = ({ activeGroupId, groupId, setActiveGroup, text }) => {
  const onClick = () => setActiveGroup(groupId)
  const color = { color: activeGroupId === groupId ? "primary" : "secondary" }
  return (
    <Button
      variant="navlink"
      onClick={onClick}
      sx={{ mr: 3, letterSpacing: 1, cursor: "pointer", ...color }}
    >
      {text}
    </Button>
  )
}

FilterLink.propTypes = {
  activeGroupId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  setActiveGroup: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

export default FilterLink
